import React, { Component, Fragment, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//
import Colors from "../../../commons/Colors";
//

import { setSnackbar, setTimer, setProfileDialog } from "../../../actions/appActions";
import { Avatar, Button, Card, CardActions, CardContent, FormControl, Icon, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TablePagination, TextField } from "@material-ui/core";
import { ActivityIndicator, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Image } from "@material-ui/icons";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { BRANDING } from "../../../../branding";
import { GetHandler, PostHandler } from "../../../handlers";
import LCardGrid from '../../../components/List/cardgrid'
import moment from "moment";

const localizedTextsMap = {
    MuiTablePagination: {
        labelDisplayedRows: ({ from, to, count }) =>
            `${from} - ${to} de ${count}`,
    },
    columnMenuUnsort: "Não classificado",
    columnMenuSortAsc: "Classificar por ordem crescente",
    columnMenuSortDesc: "Classificar por ordem decrescente",
    columnMenuFilter: "Filtro",
    columnMenuHideColumn: "Ocultar",
    columnMenuShowColumns: "Mostrar colunas",
    columnManage: 'Gerenciar',
    noRow: 'Não há registros'
};

export function DependentsCreate(props) {

    const auth = JSON.parse(localStorage.getItem('user_info'));

    const [nome, setNome] = React.useState(props?.nome ?? "");
    const [foto, setFoto] = React.useState(props?.foto ?? undefined);
    const [img, setImg] = React.useState(undefined);
    const [sobrenome, setSobrenome] = React.useState(props?.sobrenome ?? "");
    const [filiacao, setFiliacao] = React.useState(props?.filiacao ?? "");
    const [datanascimento, setDatanascimento] = React.useState(props?.datanascimento ?? moment().format('yyyy-MM-DD'));
    const [cpf, setCPF] = React.useState(props?.cpf ?? "");
    const [sexo, setSexo] = React.useState(props?.sexo ?? "");
    const [requiredDocs, setRequiredDocs] = React.useState([]);
    const [patientDocs, setPatientDocs] = React.useState([]);
    const [docs, setDocs] = React.useState([]);
    const [profileDocs, setProfileDocs] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [codigoConfirmacao, setCodigoConfirmacao] = React.useState('');
    const [codigoData, setCodigoData] = React.useState(undefined);
    const [enviaDepCodConfirmacao, setEnviaDepCodConfirmacao] = React.useState(true);


    const fileContainer = useRef();

    const onLoad = async () => {
        await GetHandler(`configuracoes/documentosExigidos`, {
            perfilId: BRANDING?.perfilId,
            tipoDoc: 1,
            exibeCadastro: true
        }).then(res => {
            setRequiredDocs(res?.data?.data ?? []);
            setLoading(false)
        }).catch(e => {
            setLoading(false);
        });

        let request = await GetHandler(`pacientes/${auth?.id}`, {
            perfilId: BRANDING?.perfilId,
        });

        const data = request?.data?.data;

        if ([0,3].includes(data?.docVerificado?.status)) {
            setLoading(true)
            await GetHandler(`configuracoes/documentosExigidos`, {
                perfilId: BRANDING?.perfilId,
                tipoDoc: 2,
                exibeCadastro: true
            }).then(res => {
                setProfileDocs(res?.data?.data ?? []);
                setLoading(false)
            }).catch(e => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }

    }
    if (requiredDocs.length == 0) {
        onLoad();
    }



    const onSave = async () => {
        setLoading(true);
        const auth = JSON.parse(localStorage.getItem("user_info"));
        if (props?.onSave != undefined) {

            props.onSave();
        }

        let data = {
            isDependente: true,
            pacIdResponsavel: auth?.pacienteId ?? auth?.id,
            nome: nome,
            sobrenome: sobrenome,
            sexo: sexo,
            cpf: cpf.replace(/\D/g, ""),
            tipoDependente: filiacao,
            dataNascimento: datanascimento,
            perfilId: auth?.perfilId ?? auth?.perfilUsuarioId,
            dependenteDoc: [],
            pacienteDoc: [],
            enviaDepCodConfirmacao
        };

        if (enviaDepCodConfirmacao == false) {
            data.codigoConfirmacao = codigoConfirmacao;
        }

        if (foto != undefined) {
            data.foto = foto;
        }

        patientDocs.map((doc) => {
            data.pacienteDoc.push({
                id: doc?.id,
                arquivo: doc?.file
            })
        });

        docs.map((doc) => {
            data.dependenteDoc.push({
                id: doc?.id,
                arquivo: doc?.file
            })
        });
        console.log('TESTE:: ', data, patientDocs)
        let change = await PostHandler(
            `pacientes`,
            data,
            props.navigation
        );
        if (change?.data?.success == true) {
            let message = change?.data?.message ?? "Enviado com Sucesso!";
            message +=' via ';
            let from = [];

            if(change?.data?.data?.sms == true){
                from.push('SMS');
            }

            if(change?.data?.data?.email == true){
                from.push('E-Mail');
            }

            if(change?.data?.data?.whatsapp == true){
                from.push('WhatsApp');
            }

            alert(message + from.join(', '));
            setEnviaDepCodConfirmacao(false);

            if (codigoConfirmacao?.length > 0) {
                window.location.href = "/dependents";
            }

            await onLoad();
            setLoading(false)
        } else {
            alert(change?.data?.message ?? "Problema ao cadastrar!");
        }
        setLoading(false)
    };

    const styles = {
        listItem: {
            borderBottomColor: "grey",
            borderBottomWidth: 1,
            marginHorizontal: 15,
        },
    };

    if (enviaDepCodConfirmacao == false) {
        return (
            <div>
                <div style={{ marginTop: 30, backgroundColor: Colors.white, maxWidth: 950, padding: 10, borderRadius: 10 }}>
                    <ScrollView style={{ innerHeight: 170 }}>
                        <div>

                            <View
                                style={{
                                    marginTop: 10,
                                    paddingBottom: 25,
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <InputLabel style={{ marginBottom: 15 }}>Insira o código de confirmação</InputLabel>
                                <TextField
                                    size="small"
                                    style={{ width: window.innerWidth >= 680 ? 180 : "100%" }}
                                    label="Código"
                                    variant="outlined"
                                    value={codigoConfirmacao}
                                    onChange={(e) => {
                                        setCodigoConfirmacao(e.target.value);
                                    }}
                                />
                            </View>
                        </div>
                    </ScrollView>
                </div>
                <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
                    <Button disabled={loading} variant={"contained"} color={"primary"} onClick={onSave}>
                        Enviar
                    </Button>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div style={{ backgroundColor: Colors.white, maxWidth: 950, padding: 10, borderRadius: 10 }}>
                <ScrollView style={{ innerHeight: 170 }}>
                    <div>
                        <View
                            style={{
                                paddingBottom: 25,
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <View>
                                <label htmlFor="file">
                                    <Avatar
                                        style={{ borderWidth: 1, borderColor: Colors.primary }}
                                        src={img}
                                        alt={'send user profile image'}
                                    >
                                        {(nome.length > 0 ? nome : "Dep")?.charAt(0)}{" "}
                                    </Avatar>
                                </label>
                            </View>
                            <View style={{ marginStart: 25 }}>
                                <small>* clique na foto ao lado para alterar.</small>
                                <input
                                    id={"file"}
                                    style={{ display: "none" }}
                                    type={"file"}
                                    //accept="image/*"
                                    onInput={async (e) => {
                                        const foto = e.target.files[0];

                                        if (foto) {
                                            foto.uri = e.target.value;
                                            let file = e.target.files[0];
                                            file.uri = e.target.files[0].value

                                            var reader = new FileReader();
                                            reader.readAsDataURL(file);
                                            reader.onloadend = async function (e) {
                                                setImg(await reader.result)
                                            }
                                            setFoto(foto)
                                        }
                                    }}
                                />
                            </View>
                        </View>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexWrap: "wrap",
                            flexDirection: window.innerWidth >= 680 ? "row" : "column",
                            justifyContent: window.innerWidth >= 680 ? "space-between" : undefined,
                            width: window.innerWidth >= 680 ? 450 : "100%",
                        }}
                    >
                        <div>
                            <TextField
                                size="small"
                                style={{ width: window.innerWidth >= 680 ? 180 : "100%" }}
                                label="Nome"
                                variant="outlined"
                                value={nome}
                                onChange={(e) => {
                                    setNome(e.target.value);
                                }}
                            />
                        </div>
                        <div style={{ marginTop: window.innerWidth >= 680 ? undefined : 10 }}>
                            <TextField
                                size="small"
                                style={{
                                    width: window.innerWidth >= 680 ? 140 : "100%",
                                }}
                                label="Sobrenome"
                                variant="outlined"
                                value={sobrenome}
                                onChange={(e) => {
                                    setSobrenome(e.target.value);
                                }}
                            />
                        </div>

                        <div style={{ marginTop: window.innerWidth >= 680 ? undefined : 10 }}>
                            <TextField
                                size="small"
                                style={{
                                    width: window.innerWidth >= 680 ? 100 : "100%",
                                }}
                                label="Filiação"
                                variant="outlined"
                                value={filiacao}
                                onChange={(e) => {
                                    setFiliacao(e.target.value);
                                }}
                            />
                        </div>
                    </div>


                    <div
                        style={{
                            display: window.innerWidth >= 680 ? "flex" : undefined,
                            flex: window.innerWidth >= 680 ? 1 : undefined,
                            flexWrap: "wrap",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: window.innerWidth >= 680 ? 450 : "100%",
                            marginTop: 10
                        }}
                    >

                        <div style={{ marginTop: window.innerWidth >= 680 ? 18 : 10 }}>
                            <TextField
                                size="small"
                                style={{ width: window.innerWidth >= 680 ? 140 : "100%" }}
                                label="CPF"
                                variant="outlined"
                                value={(cpf ?? "")?.replace(/\D/g, "")?.match(/.{1,3}/g)?.join(".")?.replace(/\.(?=[^.]*$)/, "-")}
                                onChange={(e) => {
                                    let val = e?.target?.value?.replace(/\D/g, "");
                                    if (val.length > 11) {
                                        return false;
                                    }
                                    setCPF(e.target.value);
                                }}
                            />
                        </div>
                        <div style={{ marginTop: window.innerWidth >= 680 ? undefined : 10 }}>
                            <TextField
                                size="small"
                                type="date"
                                style={{ width: window.innerWidth >= 680 ? 140 : "100%", marginTop: 18 }}
                                label="Data Nasc."
                                variant="outlined"
                                value={(datanascimento ?? "")}
                                onChange={(e) => {

                                    setDatanascimento(e.target.value);
                                }}
                            />
                        </div>

                        <View
                            style={{
                                flex: window.innerWidth >= 680 ? 0.8 : undefined,
                                marginEnd: 15,
                                marginTop: window.innerWidth >= 680 ? 0 : 10,
                            }}
                        >
                            <InputLabel style={{ fontSize: 12, marginBottom: 5, marginLeft: 5, marginTop: 5 }} id="sexo-select-label">
                                Sexo
                            </InputLabel>
                            <FormControl sx={{ m: 1 }} size="small">
                                <Select
                                    style={{ marginTop: -2 }}
                                    size={'small'}
                                    labelId="sexo-select-label"
                                    id="sexo-select"
                                    value={sexo}
                                    //label="Sexo"
                                    //aria-label="Sexo"
                                    onChange={(e) => {
                                        setSexo(e.target.value);
                                    }}
                                >
                                    {([
                                        { value: "Masculino", label: "Masculino" },
                                        { value: "Feminino", label: "Feminino" }
                                    ]).map((item) => (
                                        <MenuItem
                                            key={`sexo-${item?.value}`}
                                            value={item?.value}
                                        >
                                            {item?.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </View>
                    </div>

                </ScrollView>
                {(requiredDocs ?? []).length > 0 ? (
                    <div style={{ marginTop: 15 }}>
                        <InputLabel style={{ marginTop: 25, marginBottom: 20, fontWeight: 'bold' }}>
                            Envie os documentos do dependente
                        </InputLabel>
                        <div>
                            {(requiredDocs ?? []).map(doc => {
                                let exist = docs.find(f => f.id == doc?.id);
                                return (
                                    <div key={`docs-${doc.id}`} style={{ border: 1, marginTop: 20 }}>
                                        <div style={{ marginBottom: 10 }}>
                                            <InputLabel style={{ fontWeight: 'bold', textTransform: 'capitalize' }}> {doc?.nome} {doc.obrigatorio == 1 && (<b style={{ color: 'red' }}>*</b>)} </InputLabel>
                                        </div>
                                        <div>

                                            <View style={{ flexDirection: 'row' }}>
                                                <div>
                                                    <label htmlFor={`doc-${doc?.id}`}>
                                                        <a
                                                            style={{
                                                                cursor: 'pointer',
                                                                padding: 7,
                                                                backgroundColor: !exist ? Colors.primary : Colors.tertiary,
                                                                borderRadius: 5,
                                                                color: 'white',
                                                            }}>
                                                            {exist ? exist?.file?.name : "Escolher Documento"}
                                                        </a>
                                                    </label>
                                                </div>
                                                <div>
                                                    {exist && exist?.file?.name != undefined ?
                                                        (
                                                            <a href="#" style={{ marginLeft: 5, textDecoration: 'none' }} onClick={() => {
                                                                setLoading(true);
                                                                let ndocs = docs.filter(d => d.id != exist.id)
                                                                setDocs(ndocs);
                                                                setTimeout(() => { setLoading(false) }, 300);
                                                            }}>
                                                                Editar
                                                            </a>
                                                        ) : ''}
                                                </div>
                                            </View>
                                            {(loading == false && <input
                                                id={`doc-${doc?.id}`}
                                                style={{ display: 'none' }}
                                                type={"file"}
                                                onInput={(e) => {
                                                    setLoading(true);
                                                    let file = e.target.files[0];
                                                    file.uri = e.target.value;
                                                    let files = docs;
                                                    const fileInd = docs.findIndex(f => f.id == doc.id);
                                                    if (fileInd > -1) {
                                                        files.splice(fileInd, 1);
                                                    } else {
                                                        files.push({ id: doc.id, file });
                                                    }
                                                    setDocs(files);
                                                    setTimeout(() => {
                                                        setLoading(false);
                                                    }, 300);
                                                }}
                                            />)}
                                        </div>
                                        <div style={{ marginTop: 10, textAlign: 'center', padding: 10, backgroundColor: Colors.alert, width: '25%' }}>
                                            <small>{doc?.formatoArquivo?.nome}</small>
                                        </div>
                                    </div>
                                )
                            })}
                            <div ref={() => fileContainer}></div>
                        </div>
                    </div>) : loading == true ? <div><ActivityIndicator /></div> : undefined}

                {(profileDocs ?? []).length > 0 ? (
                    <div style={{ marginTop: 15 }}>
                        <InputLabel style={{ marginTop: 25, marginBottom: 20, fontWeight: 'bold' }}>
                            Envie os documentos do Paciente
                        </InputLabel>
                        <div>
                            {(profileDocs ?? []).map(doc => {
                                let exist = patientDocs.find(f => f.id == doc?.id);
                                return (
                                    <div key={`docs-${doc.id}`} style={{ border: 1, marginTop: 20 }}>
                                        <div style={{ marginBottom: 10 }}>
                                            <InputLabel style={{ fontWeight: 'bold', textTransform: 'capitalize' }}> {doc?.nome} {doc.obrigatorio == 1 && (<b style={{ color: 'red' }}>*</b>)} </InputLabel>
                                        </div>
                                        <div>

                                            <View style={{ flexDirection: 'row' }}>
                                                <div>
                                                    <label htmlFor={`doc-${doc?.id}`}>
                                                        <a
                                                            style={{
                                                                cursor: 'pointer',
                                                                padding: 7,
                                                                backgroundColor: !exist ? Colors.primary : Colors.tertiary,
                                                                borderRadius: 5,
                                                                color: 'white',
                                                            }}>
                                                            {exist ? exist?.file?.name : "Escolher Documento"}
                                                        </a>
                                                    </label>
                                                </div>
                                                <div>
                                                    {exist && exist?.file?.name != undefined ?
                                                        (
                                                            <a href="#" style={{ marginLeft: 5, textDecoration: 'none' }} onClick={() => {
                                                                setLoading(true);
                                                                let ndocs = docs.filter(d => d.id != exist.id)
                                                                patientDocs(ndocs);
                                                                setTimeout(() => { setLoading(false) }, 300);
                                                            }}>
                                                                Editar
                                                            </a>
                                                        ) : ''}
                                                </div>
                                            </View>
                                            {(loading == false && <input
                                                id={`doc-${doc?.id}`}
                                                style={{ display: 'none' }}
                                                type={"file"}
                                                onInput={(e) => {
                                                    setLoading(true);
                                                    let file = e.target.files[0];
                                                    file.uri = e.target.value;
                                                    let files = patientDocs;
                                                    const fileInd = patientDocs.findIndex(f => f.id == doc.id);
                                                    if (fileInd > -1) {
                                                        files.splice(fileInd, 1);
                                                    } else {
                                                        files.push({ id: doc.id, file });
                                                    }
                                                    setPatientDocs(files);
                                                    setTimeout(() => {
                                                        setLoading(false);
                                                    }, 300);
                                                }}
                                            />)}
                                        </div>
                                        <div style={{ marginTop: 10, textAlign: 'center', padding: 10, backgroundColor: Colors.alert, width: '25%' }}>
                                            <small>{doc?.formatoArquivo?.nome}</small>
                                        </div>
                                    </div>
                                )
                            })}
                            <div ref={() => fileContainer}></div>
                        </div>
                    </div>) : loading == true ? <div><ActivityIndicator /></div> : undefined}

                <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'end' }} >
                    <Button disabled={loading==true} variant={"contained"} color={"primary"} onClick={onSave}>
                        Gravar
                    </Button>
                </div>

            </div>
        </div>
    );
}



class CreateDependents extends Component {
    state = {
        data: [],
        selecteds: []
    };

    setResultScreen(visible) {
        this.setState({ resultScreen: visible });
    }

    async getData() {
        let info = JSON.parse(localStorage.getItem("user_info") ?? {});

        const request = await GetHandler(
            `pacientes/${info?.id}/dependentes`, {
            perfilId: BRANDING?.perfilId
        }
        );

        if (
            request &&
            Array.isArray(request?.data?.data) &&
            request?.data?.data?.length >= 0
        ) {
            let data = this.state.data;
            data = [...request?.data?.data];
            this.setState({
                data,
            });

        }
    }
    async componentDidMount() {
        let token = JSON.parse(localStorage.getItem("auth_token") ?? {});
        const auth = JSON.parse(localStorage.getItem("user_info"));
        if (!auth?.id) {
            window.location.href = "/";
        } else {
            await this.getData();
        }

    }
    render() {
        const columns = [
            {
                field: 'nome',
                headerName: 'Nome Completo',
                headerClassName: 'col-header',
                sortable: false,
                width: 350,
                flex: 1,
                valueGetter: (params) =>
                    `${params.row.nome || ''} ${params.row.sobrenome || ''}`,
            },
            {
                field: 'filiacao', headerName: 'Filiação', headerClassName: 'col-header', flex: 1, width: 130, valueGetter: (params) =>
                    `${params.row?.filiacao ?? ''}`
            },
            { field: 'cpf', headerName: 'CPF', headerClassName: 'col-header', flex: 1, width: 150 },
            {
                field: 'dataNascimento',
                headerName: 'Data de Nascimento',
                headerClassName: 'col-header',
                sortable: false,
                flex: 1,
                width: 160,
                valueGetter: (params) =>
                    params?.row?.dataNascimento ? `${moment(params?.row?.dataNascimento)?.format('DD/MM/yyyy')}` : ""
            },
            { field: 'sexo', headerName: 'Sexo', headerClassName: 'col-header', flex: 1 },
            {
                field: 'sobrenome', headerName: 'Status', flex: 1, headerClassName: 'col-header', renderCell: (params) =>
                    <b>{params?.row?.dadosAprovacao?.status?.nome ?? ''}</b>
            },
            {
                field: 'possuiAprovacao', headerName: 'Aprovação', headerClassName: 'col-header', flex: 1, width: 300, renderCell: (params) =>
                (<div style={{ zindex: 99 }}>
                    <div>
                        <div
                            style={{ color: params.row?.dadosAprovacao?.status?.id == 2 ? '#0A1' : Colors.red, paddingHorizontal: 10, paddingVertical: 5, textAlign: window.innerWidth >= 680 ? 'center' : 'start', borderRadius: 5 }}
                        >
                            {params?.row?.dadosAprovacao?.status?.id == 2 ? "SIM" : "NÃO"}
                        </div>
                    </div>
                </div>)
            },

        ];

        const rows = this.state.data;

        return (
            <Fragment>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 500, width: '100%' }}>
                    <DependentsCreate navigation={this.props.navigation} onSave={this.onSave} auth={this.props.sesssion} />
                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = (store) => ({
    session: store.authReducer.data,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ setSnackbar, setTimer, setProfileDialog }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateDependents);

const styles = {
    colHeader: {
        backgroundColor: '#e3ebf2',
    },
    container: {
        paddingTop: 30,
        borderRadius: 0,
        backgroundColor: Colors.primary,
        minHeight: "100%",
    },
    secView: {
        flex: 1,
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        paddingRight: 15,
        maxHeight: 65,
        paddingBottom: 15,
        bottom: 0,
        backgroundColor: Colors.secondary,
        width: "100%",
    },
    logoContainer: {
        alignItems: "center",
        justifyContent: "center",
        padding: 30,
    },
    logo: {
        height: 150,
        width: 150,
        resizeMode: "contain",
    },
    inputDefaultStyle: {
        height: 40,
    },
};
